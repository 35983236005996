/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import routes from "./lib/routes";

import { Colors, Registry, Router, Storage, Utils } from "@lightningjs/sdk";
import { Menu, Modal, AuthPopup } from "./widgets";
import { Background, LoadingCircle } from "./components";
import { appPlatformKey, storageGetLanguage } from "./lib/storage";
import { onAppExit, PLATFORMS } from "./lib/helpers";
import ExitPopup from "./widgets/ExitPopup/ExitPopup";
import { KEYS } from "./lib/utils";

export default class App extends Router.App {
    _setup() {
        Router.startRouter(routes);
    }

    static getFonts() {
        return [
            { family: "Poppins", url: Utils.asset("fonts/Poppins-Regular.ttf") },
            { family: "Poppins", url: Utils.asset("fonts/Poppins-Bold.ttf"), descriptors: { weight: 700 } },
            { family: "Poppins", url: Utils.asset("fonts/Poppins-Medium.ttf"), descriptors: { weight: 500 } },
            { family: "Poppins", url: Utils.asset("fonts/Poppins-SemiBold.ttf"), descriptors: { weight: 600 } }
        ];
    }

    static colors() {
        return {
            pageBackground: "#010101",
            // pageBackground: "#969696",
            background: "#191919",
            backgroundContrast: "#212121",
            focus: "#FAC200",
            white: "#ffffff",
            black: "#000000"
        };
    }

    static _template() {
        return {
            collision: true,
            Pages: {
                w: 1920,
                h: 1080,
                color: Colors("white").get(),
                // this hosts all the pages
                // forceZIndexContext: true,
                collision: true
            },
            Loading: {
                rect: true,
                w: 1920,
                h: 1080,
                color: Colors("pageBackground")
                    .alpha(0.8)
                    .get(),
                visible: false,
                zIndex: 99,
                Label: {
                    type: LoadingCircle
                }
            },
            Background: {
                type: Background
            },
            Widgets: {
                Menu: { type: Menu },
                Modal: { type: Modal },
                AuthPopup: { type: AuthPopup },
                ExitPopup: { type: ExitPopup }
            },
            Debug: {
                rect: true,
                zIndex: 9999999,
                w: 400,
                h: 400,
                y: 1080 - 400,
                x: 0,
                flex: { direction: "column", gap: 10 },
                color: Colors("pageBackground").get(),
                alpha: 0
            }
        };
    }

    _construct() {
        this.listeners = {
            exitApp: () => {
                this._exitApp();
            },
            debug: event => {
                this._debug(event);
            }
        };
    }

    static language() {
        return storageGetLanguage() || "en";
    }

    async _init() {}

    _attach() {
        ["exitApp", "debug"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });

        Registry.addEventListener(window, "debug", this._debug.bind(this));
        Registry.addEventListener(window, "handleAppExit", this._handleAppClose);
    }

    _detach() {
        ["exitApp", "debug"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });

        Registry.removeEventListener(window, "debug", this._debug);
        Registry.removeEventListener(window, "handleAppExit", this._handleAppClose);
    }

    _debug(text) {
        console.log(`[${Date.now()}] ${typeof text === "string" ? text : text.detail}`);

        // this.tag("Debug").children = [
        //     {
        //         text: {
        //             text: `[${Date.now()}] ${typeof text === "string" ? text : text.detail}`,
        //             fontSize: 16,
        //             wordWrapWidth: 400,
        //             wordWrap: true
        //         }
        //     },
        //     ...this.tag("Debug").children
        // ];
    }

    _handleMenu() {
        Router.navigate(routes.root);
    }

    _handleLast() {
        Router.back();
    }

    _captureKey(e) {
        e.preventDefault();

        if (Router.isNavigating()) {
            return true;
        }

        const keyCode = e.keyCode || e.code || e.keyIdentifier;

        console.log("keyCode", keyCode);

        if (!e.keyCode && e.keyCode !== 0 && keyCode) {
            window.dispatchEvent(new KeyboardEvent("keydown", { ...e, keyCode: keyCode }));
            return true;
        }

        const activeHash = Router.getActiveHash();

        if (activeHash === "playback") return false;

        // up and down keys
        const delay = activeHash === "browse" && (keyCode === KEYS.top || keyCode === KEYS.bottom) ? 580 : 460;

        const now = Date.now();
        if (
            // now - this._lastKeyPressed < 150 ||
            (keyCode !== KEYS.enter && this._lastKey === keyCode && now - this._lastKeyPressed < 250) ||
            (e.repeat && now - this._lastKeyPressed < delay)
        ) {
            return true;
        }
        this._lastKeyPressed = now;
        this._lastKey = keyCode;

        return false;
    }

    toggleExitDialog() {
        return new Promise((resolve, reject) => {
            this.tag("ExitPopup").open(() => {
                resolve(true);
            }, reject);
            Router.focusWidget("ExitPopup");
        });
    }

    _handleAppClose() {
        // TODO
        this.toggleExitDialog()
            .then(confirmed => {
                // close the application
                if (confirmed) {
                    this._exitApp();
                }
            })
            .catch(e => {
                console.log("e", e);
                Router.focusPage();
            });
    }

    _exitApp() {
        onAppExit();

        try {
            this.application.closeApp();

            this._debug("TOGGLE EXIT");

            if (Storage.get(appPlatformKey) === PLATFORMS.samsung) {
                window.tizen.application.getCurrentApplication().exit();
            } else if (typeof navigator != "undefined" && navigator.app) {
                navigator.app.exitApp();
            } else if (typeof window.Android !== "undefined" && window.Android.exitApp) {
                window.Android.exitApp();
            } else if (typeof window.Android !== "undefined" && window.Android.closeApp) {
                window.Android.closeApp();
            } else {
                window.close();
            }
        } catch (e) {
            this._debug(JSON.stringify(e));
            console.log("e", e);
        }
    }
}
