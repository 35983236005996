import { Lightning, Registry, Router, Storage } from "@lightningjs/sdk";
import { ErrorModal, LoadingCircle, ModalWindow } from "../../components";
import { getSubscription, deviceToken, activateAmazonSubscription, cancelAmazonSubscription } from "../../lib/api";
import { appPlatformKey, appPlatformTokenKey, storageSaveUser } from "../../lib/storage";
import LoginPopup from "../../components/Profile/components/LoginPopup";
import LoggedInPopup from "../../components/Profile/components/LoggedInPopup";
import posthog from "posthog-js";
import SubscriptionPopup from "../../components/Profile/components/SubscriptionPopup";
import { PLATFORMS } from "../../lib/helpers";

export default class AuthPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            Modal: {
                type: ModalWindow,
                collision: true
            },
            Loading: {
                alpha: 0,
                type: LoadingCircle
            }
        };
    }

    _construct() {
        this._loginTimeout = null;
        this._open = false;
        this._loading = false;
    }

    _getFocused() {
        return this.tag("Modal");
    }

    _attach() {
        Registry.addEventListener(window, "amazonConfirmSubscription", this._amazonConfirmSubscription.bind(this));
        Registry.addEventListener(window, "amazonCancelSubscription", this._amazonCancelSubscription.bind(this));
        Registry.addEventListener(window, "triggerLoginRequired", this._triggerLoginRequired.bind(this));

        Registry.addEventListener(window, "externalSubscriptionSync", this._toggleLoader.bind(this));
    }

    _detach() {
        Registry.removeEventListener(window, "amazonConfirmSubscription", this._amazonConfirmSubscription);
        Registry.removeEventListener(window, "amazonCancelSubscription", this._amazonCancelSubscription);
        Registry.removeEventListener(window, "triggerLoginRequired", this._triggerLoginRequired.bind(this));

        Registry.removeEventListener(window, "externalSubscriptionSync", this._toggleLoader.bind(this));
    }

    async _unfocus() {
        posthog.capture("ClosedSubscriptionPopup", { token: await deviceToken() });
    }

    async open() {
        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: `open auth `
            })
        );

        posthog.capture("SubscriptionPopup", { token: await deviceToken() });

        const platform = Storage.get(appPlatformKey);

        console.log("platform", platform);
        if (platform === PLATFORMS.amazon && window.Android && window.Android.makeSubscription) {
            // v1
            window.Android.makeSubscription();
        } else if (window.Android && window.Android.createSubscription) {
            // v2
            window.Android.createSubscription();
            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: `call create sub`
                })
            );
        } else if (platform === PLATFORMS.posttv || platform === PLATFORMS.amazon || platform === PLATFORMS.google) {
            this.tag("Modal").open([
                {
                    type: SubscriptionPopup
                }
            ]);
            Router.focusWidget("AuthPopup");
        } else {
            this.tag("Modal").open([
                {
                    type: LoginPopup
                }
            ]);
            Router.focusWidget("AuthPopup");
        }
    }

    async _triggerCheckLogin() {
        if (this.triggerLogin) return;

        this.triggerLogin = true;
        await this._checkLogin();
    }

    async _checkLogin() {
        try {
            const res = await getSubscription();
            if (res.success) {
                this.triggerLogin = false;
                storageSaveUser(res);
                this.application.emit("userAuth", true);

                await this._showLoggedInPopup();
            } else {
                this._setLoginTimeout();
            }
        } catch (e) {
            this._setLoginTimeout();
        }
    }

    _setLoginTimeout() {
        if (this.triggerLogin) {
            const _this = this;
            this._loginTimeout = Registry.setTimeout(async () => {
                await _this._checkLogin();
            }, 5000);
        }
    }

    async _showLoggedInPopup() {
        posthog.capture("SuccessfulLogin", {
            token: await deviceToken()
        });

        Router.focusWidget("AuthPopup");

        this.tag("Modal").open([
            {
                type: LoggedInPopup
            }
        ]);
    }

    async $subscribed() {
        await this._showLoggedInPopup();
    }

    async $postTvSubscribed() {
        // treat posttv token as user ID
        storageSaveUser({ userId: Storage.get(appPlatformTokenKey), canWatch: true });

        await this._showLoggedInPopup();
    }

    async $checkLogin() {
        await this._triggerCheckLogin();
    }

    _showLoading() {
        Router.focusWidget("AuthPopup");

        this._setState("LoadingState");
    }

    _hideLoading() {
        this._setState("PopupState");
    }

    async _amazonConfirmSubscription(e) {
        let timeout = 400;
        this._showLoading();
        // console.log("----------");
        // console.log("AMAZON SUB", e.detail.userId);

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: "! AMAZON SUB " + JSON.stringify(e.detail)
            })
        );
        try {
            // TODO: check if successful or not and show error conditionally!!
            const data = await activateAmazonSubscription(e.detail);

            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: "! AMAZON SUB result" + JSON.stringify(data)
                })
            );
            if (data.success) {
                storageSaveUser({ ...data, userId: e.detail.userId, canWatch: true });

                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail:
                            "! AMAZON SUB set user" +
                            JSON.stringify({ ...data, userId: e.detail.userId, canWatch: true })
                    })
                );

                Router.focusPage();
            } else {
                timeout = 20;

                this._handleError();
            }
        } catch (e) {
            timeout = 20;

            console.log("ERR");
            console.log(e);
            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: "! AMAZON SUB ERR" + JSON.stringify(e)
                })
            );
            this.tag("Modal").open([
                {
                    type: ErrorModal
                }
            ]);
        }

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: "----- END AMAZON SUB ----"
            })
        );

        Registry.setTimeout(() => {
            this._hideLoading();
        }, timeout);
    }

    async _amazonCancelSubscription(e) {
        console.log("AMAZON SUB CANCEL", e.detail.userId);
        try {
            // TODO: check if successful or not and show error conditionally!!
            await cancelAmazonSubscription(e.detail);

            storageSaveUser({ userId: e.detail.userId, canWatch: false });
        } catch (e) {
            console.log(e);

            this.tag("Modal").open([
                {
                    type: ErrorModal
                }
            ]);
        }

        Router.focusWidget("AuthPopup");
    }

    _handleError() {
        this.tag("Modal").open([
            {
                type: ErrorModal
            }
        ]);

        Router.focusWidget("AuthPopup");
    }

    _triggerLoginRequired() {
        this.tag("Modal").open([
            {
                type: SubscriptionPopup
            }
        ]);

        Router.focusWidget("AuthPopup");
    }

    _handleClick() {}

    _captureKey() {
        return this._loading;
    }

    // amazon V2
    _toggleLoader({ detail }) {
        const { syncing, error } = detail;

        this._loading = syncing;

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: `syncing: ${syncing}, error: ${error}`
            })
        );

        this.patch({
            Loading: {
                alpha: syncing ? 1 : 0
            }
        });

        if (syncing) {
            Router.focusWidget("AuthPopup");
        } else if (error) {
            this._handleError();
        } else if (!syncing) {
            Router.focusPage();
        }
    }

    static _states() {
        return [
            class PopupState extends this {},
            class LoadingState extends this {
                $enter() {
                    this.patch({
                        Loading: {
                            alpha: 1
                        }
                    });
                }
                $exit() {
                    this.patch({
                        Loading: {
                            alpha: 0
                        }
                    });
                }

                _captureKey() {}
            }
        ];
    }
}
