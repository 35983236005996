import { Colors, Lightning, Router, Storage } from "@lightningjs/sdk";
import { List } from "@lightningjs/ui";
import { Agreement, Auth, ProfileLanguage, Subscription, Support } from "../components";
import { MENU_PAGE_IDS, pageTransition } from "../lib/utils";
import { appPlatformKey, storageGetUser } from "../lib/storage";
import { PLATFORMS } from "../lib/helpers";

export default class Settings extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            rect: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            Page: {
                collision: true,
                x: 96,
                y: 86,
                w: 1920,
                h: 1080,
                Top: {
                    collision: true,
                    type: List,
                    direction: "row",
                    spacing: 8
                },
                Bottom: {
                    collision: true,
                    y: 458,
                    type: List,
                    direction: "row",
                    spacing: 8
                }
            }
        };
    }

    _handleBack() {
        Router.navigate("home");
    }

    _init() {
        this.tag("Bottom").add([
            { type: Agreement, w: 892, h: 450, widgets: this.widgets, column: 0, row: 1 },
            { type: Support, w: 892, h: 450, widgets: this.widgets, column: 1, row: 1 }
        ]);

        for (const wrapper of this.tag("Bottom").children) {
            wrapper?.patch({
                collision: true
            });
        }

        const data = storageGetUser();

        this._updateData(data);

        this.listeners = {
            userAuth: (isLoggedIn) => {
                this._userLoggedIn(isLoggedIn);
            }
        };
    }

    _attach() {
        ["userAuth"].forEach((event) => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["userAuth"].forEach((event) => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _active() {
        this.widgets.menu.setActive(MENU_PAGE_IDS.settings);

        this._setState("TopState");

        // if (!data) {
        //     // TODO: check if camel case can be used and widgets to be retrived
        //     this.widgets.authpopup.open();
        //     Router.focusWidget("AuthPopup");
        // }
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _setTopItems() {
        this._topItems = [];

        const platform = Storage.get(appPlatformKey);
        if (platform !== PLATFORMS.posttv && platform !== PLATFORMS.amazon && platform !== PLATFORMS.google) {
            const user = storageGetUser();
            const itemWidth = user ? 592 : 892;

            this._topItems = [{ type: Auth, w: itemWidth, h: 450 }];

            if (storageGetUser()) {
                this._topItems.push({ type: Subscription, w: itemWidth, h: 450 });
            }

            this._topItems.push({ type: ProfileLanguage, w: itemWidth, h: 450 });
        } else {
            this._topItems.push({ type: ProfileLanguage, w: 1792, h: 450 });
        }

        this.tag("Top").add(
            this._topItems.map((item, index) => {
                item.widgets = this.widgets;
                item.column = index;
                item.row = 0;
                return item;
            })
        );
    }

    _userLoggedIn() {
        const data = storageGetUser();

        this._updateData(data);
    }

    _updateData(data) {
        this._setTopItems();

        this.patch({
            Page: {
                Top: {
                    items: this._topItems.map((i) => {
                        i.loggedIn = !!data;
                        i.username = data ? data.username : "";
                        i.price = data ? data.price : null;
                        return i;
                    })
                }
            }
        });

        for (const wrapper of this.tag("Top").children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }

    $itemClicked({ row, column, id }) {
        const topIndex = this.tag("Top").items.findIndex((item) => item.assignedID === id);
        if (topIndex > -1) {
            this._setState("TopState");
            this.tag("Top").setIndex(topIndex);
        } else {
            const bottomIndex = this.tag("Bottom").items.findIndex((item) => item.assignedID === id);
            this._setState("BottomState");
            this.tag("Bottom").setIndex(bottomIndex);
        }
    }

    static _states() {
        return [
            class TopState extends this {
                _getFocused() {
                    return this.tag("Top");
                }

                _handleDown() {
                    this._setState("BottomState");
                }
            },
            class BottomState extends this {
                _getFocused() {
                    return this.tag("Bottom");
                }

                _handleUp() {
                    this._setState("TopState");
                }
            }
        ];
    }
}
