import { storageGetUser, storageRemoveUser, storageSaveUser } from "../lib/storage";
import { getSubscription, syncExternalSubscription } from "../lib/api";
import { posthogCaptureAppStart } from "../lib/utils";
import { Registry } from "@lightningjs/sdk";

// eslint-disable-next-line no-undef
// window.Android = typeof Android !== "undefined" && Android !== null ? Android : undefined;

console.log(`Attach external sub file ` + typeof window.Android);
try {
    // v1 to be removed
    if (window.Android) {
        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: "SETUP AM"
            })
        );

        window.getUser = (userId) => {
            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: `GET USER: ${userId}`
                })
            );
            // console.log("get user", userId);
            //
            // const user = storageGetUser();
            //
            // window.Android.setAmazonUserData(user);

            const user = storageGetUser();
            storageSaveUser({ userId: userId, canWatch: userId && user && user.canWatch && user.userId === userId });

            getSubscription()
                .then((data) => {
                    if (data.success) {
                        storageSaveUser(data);
                    } else {
                        storageSaveUser({ userId: userId, canWatch: false });
                    }

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `SET AM CAN WATCH: ${userId} ${JSON.stringify(data)}`
                        })
                    );

                    window.Android.setAmazonUserData(JSON.stringify(data));
                })
                .catch(() => {
                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: "SET AM CAN WATCH ERROR"
                        })
                    );

                    storageSaveUser({ userId: userId, canWatch: false });
                });
        };

        window.setAmazonUserId = (userId) => {
            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: `SET AM UID: ${userId}`
                })
            );
            if (userId) {
                // const user = storageGetUser();
                storageSaveUser({ userId: userId });

                getSubscription()
                    .then((data) => {
                        if (data.success) {
                            storageSaveUser(data);
                        } else {
                            storageSaveUser({ userId: userId, canWatch: false });
                        }

                        window.dispatchEvent(
                            new CustomEvent("debug", {
                                detail: `SET AM CAN WATCH: ${userId} ${JSON.stringify(data)}`
                            })
                        );

                        window.Android.setAmazonUserData(JSON.stringify(data));
                    })
                    .catch((err) => {
                        window.dispatchEvent(
                            new CustomEvent("debug", {
                                detail: err
                            })
                        );

                        storageSaveUser({ userId: userId, canWatch: false });
                    })
                    .finally(() => {
                        posthogCaptureAppStart();
                    });
            } else {
                storageRemoveUser();
                posthogCaptureAppStart();
            }
        };

        window.confirmSubscription = (userId, from, to, platform) => {
            const user = storageGetUser();
            userId = userId || (user ? user.userId : "");
            platform = platform ? platform : "amazon";

            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: `CONFIRM SUB  platform: ${platform}, userId: ${userId}, dateFrom: ${from} dateTo: ${to}`
                })
            );

            window.dispatchEvent(
                new CustomEvent("amazonConfirmSubscription", {
                    detail: {
                        userId,
                        from,
                        to,
                        platform
                    }
                })
            );
        };

        window.cancelSubscription = (userId, to, platform) => {
            window.dispatchEvent(
                new CustomEvent("debug", {
                    detail: `CANCEL SUB  platform: ${platform}, userId: ${userId}`
                })
            );

            const user = storageGetUser();
            userId = userId || (user ? user.userId : "");
            platform = platform ? platform : "amazon";

            window.dispatchEvent(
                new CustomEvent("amazonCancelSubscription", {
                    detail: {
                        userId: userId,
                        to: to,
                        platform: platform
                    }
                })
            );
        };

        window.triggerLoginRequired = () => {
            window.dispatchEvent(new CustomEvent("triggerLoginRequired", {}));
        };
    }

    // v2
    if (window.Android) {
        console.log(`window.Android is present, Define methods for amazon/google`);

        window.handleAppBackPressed = () => {
            window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 8 }));
        };

        window.handleAppExit = () => {
            window.dispatchEvent(
                new CustomEvent("handleAppExit", {
                    detail: {}
                })
            );
        };

        window.setSubscriptionData = async (data) => {
            console.log("Set subscription data is called!!");
            try {
                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `SYNC SUB DATA`
                    })
                );

                // show loading if user initiated sub
                window.dispatchEvent(
                    new CustomEvent("externalSubscriptionSync", {
                        detail: { syncing: true }
                    })
                );

                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `setSubscriptionData recieved data: ${data}`
                    })
                );

                // get info
                // GoogleIAPData = { purchaseToken: "", subscriptionId: "" };
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    console.log(e);
                }

                let hasError;
                try {
                    // google
                    // purchaseToken,
                    // subscriptionId,

                    // amazon
                    // userId,
                    // receiptId;
                    const res = await syncExternalSubscription(data);

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `user data to save: ${JSON.stringify(res)}`
                        })
                    );
                    storageSaveUser({
                        userId: data.userId || data.purchaseToken,
                        ...storageGetUser(),
                        ...res
                    });
                } catch (e) {
                    console.log("Set subscription data error");
                    console.log(JSON.stringify(e));
                    hasError = true;
                }

                Registry.setTimeout(() => {
                    window.dispatchEvent(
                        new CustomEvent("externalSubscriptionSync", {
                            detail: { syncing: false, error: hasError }
                        })
                    );
                }, 1400);
            } catch (e) {
                console.log(e);
                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `ERR SYNC SUB DATA`
                    })
                );
            }
        };
    } else {
        console.log("window.Android is not present, skip");
    }
} catch (e) {
    window.dispatchEvent(
        new CustomEvent("debug", {
            detail: `Defining methods err `
        })
    );
    console.log("AMAZON INIT ERR " + e);
}
