// we import all the pages that we want to add to our app
import { Boot, Browse, Error, Favorites, Home, Playback, Search, Series, Settings } from "../pages";
import {
    deviceToken,
    getAppInfo,
    getCategories,
    getFavorites,
    getMovie,
    getMovies,
    getSubscription,
    getTrending
} from "./api";
import { appPlatformKey, storageAppStartTimeKey, storageGetUser, storageSaveUser } from "./storage";

import posthog from "posthog-js";
import { Profile, Router, Storage } from "@lightningjs/sdk";
import { PLATFORMS } from "./helpers";
import { posthogCaptureAppStart } from "./utils";

export default {
    boot: () => {
        return new Promise((resolve) => {
            (async () => {
                const { platform: queryPlatform, token: queryToken } = Router.getQueryStringParams();
                let profilePlatform = await Profile.platform();

                if (profilePlatform && profilePlatform.toLowerCase() === "wpe") {
                    profilePlatform = "comcast";
                }

                const userAgent = navigator.userAgent.toLowerCase();

                const isSamsung = typeof window.tizen !== "undefined";
                const isLG = /(webos|web0s)/i.test(userAgent);

                const tvPlatform = isSamsung ? PLATFORMS.samsung : isLG ? PLATFORMS.lg : undefined;

                Storage.set(appPlatformKey, tvPlatform || queryPlatform || profilePlatform);
                // Storage.set(appPlatformTokenKey, queryToken || "");

                // posttv
                if (queryToken) {
                    Storage.set("uid", queryToken);
                }

                Storage.set(storageAppStartTimeKey, new Date().getTime());

                try {
                    const appData = await getAppInfo();
                    Storage.set("app", appData);

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `appData: ${JSON.stringify(Storage.get("app"))}`
                        })
                    );
                } catch (e) {
                    console.log(e);

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `appData error: ${JSON.stringify(e)}`
                        })
                    );
                }

                const token = await deviceToken();
                const platform = Storage.get(appPlatformKey);

                posthog.init("phc_94W8u3gmcpRPE5eL4vTy8tQhrZaSzhkCJIkHx0eMsmi", {
                    api_host: "https://app.posthog.com"
                });

                posthog.identify(token, { platform });

                // if (platform === PLATFORMS.posttv) return;

                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `BOOT platform: ${platform}`
                    })
                );

                // v1
                if (platform === PLATFORMS.amazon && window.Android && window.Android.getAmazonUserId) {
                    window.Android.getAmazonUserId();

                    resolve();
                    return;
                }

                console.log("PLATFORM", Storage.get(appPlatformKey));

                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `typeof window.Android?.getUserId: ${typeof window.Android?.getUserId}`
                    })
                );

                try {
                    // v2
                    if (platform === PLATFORMS.amazon && window.Android?.getUserId) {
                        // amazon
                        const userId = window.Android.getUserId();

                        storageSaveUser({
                            to: null,
                            canWatch: false,
                            userId
                        });

                        window.dispatchEvent(
                            new CustomEvent("debug", {
                                detail: `GET USER ID ${userId}`
                            })
                        );
                    } else if (window.Android?.getPurchaseToken) {
                        // google
                        const userId = window.Android.getPurchaseToken();

                        console.log("window.Android.getSubscriptionData(), ", window.Android.getSubscriptionData());

                        storageSaveUser({
                            to: null,
                            canWatch: false,
                            userId
                        });

                        window.dispatchEvent(
                            new CustomEvent("debug", {
                                detail: `GET USER ID ${userId}`
                            })
                        );
                    }

                    const subscription = await getSubscription();

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `GET sub ${JSON.stringify(subscription)}`
                        })
                    );
                    if (subscription.success) {
                        storageSaveUser(subscription);
                    } else {
                        const user = storageGetUser();
                        storageSaveUser({ ...(user || {}), canWatch: false });
                    }
                } catch (e) {
                    const user = storageGetUser();
                    storageSaveUser({ ...(user || {}), canWatch: false });

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `GET sub err ${JSON.stringify(e)}`
                        })
                    );
                }

                posthogCaptureAppStart();

                resolve();
            })();
        });
    },
    // bootComponent: Boot,
    // First we define a root, this is the hash were the browser will point to
    // at the moment that you boot your app
    root: "home",
    // Next we can define the rest of our routes
    routes: [
        {
            path: "$",
            component: Boot
        },
        {
            // this is a one level deep route.
            path: "home",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Home,
            widgets: ["Menu", "Modal", "AuthPopup", "ExitPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getTrending()
                        .then((data) => {
                            posthog.capture("HomePageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            // this is a one level deep route.
            path: "browse",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Browse,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getCategories()
                        .then((data) => {
                            posthog.capture("BrowsePageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            cache: 60 * 30, // 30 minutes
            options: {
                clearHistory: true
            }
        },
        {
            // this is a one level deep route.
            path: "series/:id",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Series,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page, { id }) => {
                return new Promise((resolve, reject) => {
                    getMovies(id)
                        .then((data) => {
                            posthog.capture("MovieSelected", {
                                movie_title: data.movie.title || ""
                            });

                            console.log("PAGE data", data, data.isFavorite);
                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },
        {
            path: "playback/:id",
            component: Playback,
            widgets: ["Modal", "AuthPopup"],
            on: async (page, { id }) => {
                return new Promise((resolve, reject) => {
                    getMovie(id)
                        .then((data) => {
                            // set property on the page
                            page.data = data;

                            if (!data.success) {
                                const user = storageGetUser();
                                if (user && user.canWatch) {
                                    reject();
                                } else {
                                    resolve();
                                }
                            } else {
                                posthog.capture("Playback", {
                                    movie_title: data.movie.title || ""
                                });

                                resolve();
                            }
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },
        {
            path: "favorites",
            component: Favorites,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getFavorites()
                        .then((data) => {
                            posthog.capture("FavoritesPageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            path: "Search",
            component: Search,
            widgets: ["Menu", "Modal", "AuthPopup"],
            cache: 60 * 30, // 30 minutes,
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("SearchPageLoad");

                    resolve();
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            path: "Settings",
            component: Settings,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("SettingPageLoad");

                    resolve();
                });
            }
        },
        {
            path: "!",
            component: Error,
            widgets: ["Modal"],
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("Error");

                    resolve();
                });
            }
        }
    ]
};
